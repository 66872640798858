<template>
    <el-row class="table-wrapper">
        <el-col
            :span="12"
            class="left-wrapper"
        >
            <div class="left-title">系统标准字段名称</div>
            <div
                v-for="(item, index) in systemExcelHeader"
                :key="index"
                class="left-content"
            >
                <span
                    v-show="item.required"
                    class="required"
                    >*</span
                >
                <span>{{ item.label }}</span>
            </div>
        </el-col>
        <el-col
            :span="12"
            class="right-wrapper"
        >
            <div class="right-title">导入文件表头字段</div>
            <div v-if="show">
                <div
                    v-for="(item, index) in systemExcelHeader"
                    :key="index"
                    class="right-content"
                >
                    <el-select
                        v-model="keyMap[item.label]"
                        filterable
                        clearable
                        :span="12"
                        @change="change"
                        class="right-select"
                    >
                        <el-option
                            v-for="(item, index) in uploadExcelHeader"
                            :disabled="item.disabled"
                            :key="index"
                            :value="item.label"
                            :label="item.label"
                        />
                    </el-select>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
export default {
    props: {
        systemExcelHeader: {
            type: Array,
        },
        uploadExcelHeader: {
            type: Array,
        },
        keyMap: {
            type: Object,
        },
        show: {
            type: Boolean,
        },
    },
    mounted() {
        console.log(this.keyMap, "keymap");
        console.log(this.systemExcelHeader, "systemExcelHeader");
        console.log(this.uploadExcelHeader, "uploadExcelHeader");
    },
    methods: {
        change(data) {
            this.$eventDispatch("importSelChange", data);
        },
    },
};
</script>

<style scoped lang="scss">
.table-wrapper {
    background: #fcfcfc;
    border: 1px solid #e1e3e6;
    margin: 0 10%;
    .left-wrapper {
        border-right: 1px solid #e6e6e6;
        padding: 0 28px;
        margin: 16px 0 21px 0;
        .left-title {
            font-size: 14px;
            font-weight: bold;
            color: #737373;
            line-height: 40px;
        }
        // .left-content {
        //     padding: 13px 0;
        //     color: #878787;
        //     font-size: 14px;
        //     text-align: center;
        //     border-bottom: 1px dashed #e6e6e6;
        //     .required {
        //         color: #f56c6c;
        //         padding-right: 5px;
        //     }
        // }
        .left-content {
            height: 46px;
            line-height: 46px;
            color: #878787;
            font-size: 14px;
            text-align: center;
            border-bottom: 1px dashed #e6e6e6;
            box-sizing: border-box;
            .required {
                color: #f56c6c;
                padding-right: 5px;
            }
        }
    }
    .right-wrapper {
        padding: 0 28px;
        margin: 16px 0 21px 0;
        .right-title {
            font-size: 14px;
            font-weight: bold;
            color: #3C7FFF;
            line-height: 40px;
        }
        .right-select {
            width: 100%;
            margin-top: 10px;
        }
        .right-content {
            height: 46px;
        }
    }
}
</style>
