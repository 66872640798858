<template>
    <div class="operation-btn-view">
        <!-- <el-button
            style="margin-left: 0px"
            type="primary"
            v-hasPermi="addBtn"
            @click="add"
            >添加</el-button
        > -->
        <div class="btn-left">
            <div v-hasPermi="addBtn" class="inline"></div>
            <!-- <el-button
                type="text"
                class="primary"
                @click="selAll"
                >全选</el-button
            >
            <el-button
                type="text"
                class="primary"
                @click="counterSel"
                >反选</el-button
            >
            <el-button
                type="text"
                class="danger"
                @click="cleanSel"
                >清除</el-button
            > -->
            <slot name="left"></slot>
            <!-- <div class="inline"></div> -->
        </div>
        <div class="btn-right">
            <slot name="rightBtn">
                <slot name="right"></slot>
                <div v-hasPermi="importBtn.concat(exportBtn)" class="inline"></div>
                <!-- <el-dropdown
                    trigger="click"
                    style="background-color: #ffffff"
                >
                    <el-button
                        type="enquiry"
                        v-hasPermi="importBtn"
                    >
                        导入<i class="el-icon-caret-bottom el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu
                        slot="dropdown"
                        style="background-color: #ffffff"
                    >
                        <el-dropdown-item>
                            <div style="display:flex">
                                <el-button
                                    type="text"
                                    class="hover"
                                    plain
                                    size="mini"
                                    @click="importAll"
                                    >基础信息</el-button>
                            </div>

                            </el-dropdown-item>
                            <el-dropdown-item>
                            <el-button
                                style="
                                    display: block;
                                    margin-left: 0px;
                                "
                                type="text"
                                class="hover"
                                plain
                                @click="importMore"
                                >更多信息</el-button
                            >
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button
                    type="enquiry"
                    style="margin-left: 10px"
                    v-hasPermi="exportBtn"
                    @click="exp"
                >
                    导出
                </el-button> -->
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "OperationBtnView",
    props: {
        parent: {
            type: String,
        },
        importBtn: {
            type: Array,
            default() {
                return [];
            }
        },
        exportBtn: {
            type: Array,
            default() {
                return [];
            }
        },
        addBtn: {
            type: Array,
        },
    },
    computed: {
        importUrl() {
            return require("../../../assets/images/import.png");
        },
        exportUrl() {
            return require("../../../assets/images/export.png");
        },
    },
    methods: {
        selAll() {
            this.$emit("selAll");
        },
        counterSel() {
            this.$emit("counterSel");
        },
        cleanSel() {
            this.$emit("cleanSel");
        },
        add() {
            this.$emit("add");
        },
        importAll() {
            this.$emit("importAll");
        },
        importMore() {
            this.$emit("importMore");
        },
        exp() {
            this.$emit("exp");
        },
        acts(name) {
            this.$emit("acts", name);
        },
    },
};
</script>
<style lang="scss" scoped>
.operation-btn-view {
    display: flex;
    box-sizing: border-box;
    padding: 0px 0px 10px 0px;
    overflow: hidden;
    background-color: #fff;
}

.btn-left {

    display: flex;
    align-items: center;
    .inline {
        margin: 0px 20px;
        width: 1px;
        height: 24px;
        background-color: #E6E6E6;
    }
}

.btn-right {
    display: flex;
    align-items: center;
    .inline {
        margin: 0px 20px;
        width: 1px;
        height: 24px;
        background-color: #E6E6E6;
    }
}

.img {
    width: 14px;
    float: left;
    margin-top: 2px;
    padding-right: 6px;
}
.el-dropdown-menu {
    padding: 0 10px;
    background-color: #ffffff !important;

    /deep/ .el-dropdown-menu__item {
        background-color: #ffffff !important;
    }

    /deep/ .el-dropdown-menu__item:hover {
        background-color: #ffffff !important;
    }

    /deep/ .el-dropdown-menu {
        background-color: #ffffff !important;
    }

    /deep/ .el-dropdown-menu:hover {
        background-color: #ffffff !important;
    }
}
.el-dropdown-menu:hover {
    background-color: #ffffff !important;
}
</style>
