<template>
    <div>
        <div
            v-if="importLoading"
            class="progress_loading"
        >
            <div class="progress_img">
                <el-progress
                    :text-inside="true"
                    :stroke-width="26"
                    :percentage="loading_number"
                ></el-progress>
            </div>
            <div class="loading_text">导入中...</div>
            <div class="import_text">导入数据较大，请耐心等待~</div>
        </div>
        <div v-else>
            <div class="second-title-wrapper">
                <div class="second-title">导入执行</div>
                <div class="line"></div>
            </div>
            <import-execute
                :show="show"
                :upload-excel-header="uploadExcelHeader"
                :system-excel-header="systemExcelHeader"
                :key-map="keyMap"
            />
            <div class="second-title-wrapper">
                <div class="second-title">导入设置</div>
                <div class="line"></div>
            </div>
            <el-row>
                <el-col
                    :span="6"
                    class="second-label"
                    >导入过程中遇到错误</el-col
                >
                <el-col :span="18">
                    <el-radio
                        v-model="importForm.errType"
                        label="1"
                        >继续执行</el-radio
                    >
                    <el-radio
                        v-model="importForm.errType"
                        label="2"
                        >终止导入</el-radio
                    >
                    <div class="second-content">
                        <p>
                            继续执行：遇到错误数据不中断，正确数据导入完成后统一导出错误信息，错误信息不导入;
                        </p>
                        <p>
                            终止导入：数据必须全部无误才可导入成功，一旦遇到错误即终止，系统导出全部数据，标记错误数据，修改后重新导入
                        </p>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col
                    :span="6"
                    class="second-label"
                    >导入过程中，遇到相同数据</el-col
                >
                <el-col :span="18">
                    <el-radio
                        v-model="importForm.sameType"
                        label="1"
                        >覆盖导入</el-radio
                    >
                    <el-radio
                        v-model="importForm.sameType"
                        label="2"
                        >追加导入</el-radio
                    >
                    <div class="second-content">
                        <p>
                            {{ importInfo.sameInfo }}
                        </p>
                        <p>追加导入：保留原数据，新导入的无效</p>
                    </div>
                </el-col>
            </el-row>
            <div style="text-align: center">
                <xk-button
                    type="primary"
                    @click="save"
                    style="margin: 0"
                    >下一步</xk-button
                >
                <xk-button
                    type="info"
                    @click="before"
                    >返回重新上传</xk-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import importExecute from "./sub/importExecute";
import {debounce} from "common-local";

export default {
    components: {
        importExecute,
    },
    props: {
        importForm: {
            type: Object,
        },
        systemExcelHeader: {
            type: Array,
        },
        uploadExcelHeader: {
            type: Array,
        },
        keyMap: {
            type: Object,
        },
        importInfo: {
            type: Object,
        },
        show: {
            type: Boolean,
        },
        importLoading: {
            type: Boolean,
        },
    },
    data() {
        return {
            loading_number: 0,
            timer: null,
        };
    },
    created() {
        console.log("importLoading", this.importLoading);
    },
    methods: {
        save: debounce(
            function () {
                this.$eventDispatch("save");
                this.startLoading();
            },
            500,
            true,
        ),
        before() {
            this.$eventDispatch("before");
        },

        startLoading() {
            for (
                this.loading_number = 0;
                this.loading_number < 100;
                this.loading_number++
            ) {
                console.log(this.loading_number, "000000000000000000000");
            }
        },
    },
};
</script>

<style scoped lang="scss">
.second-title-wrapper {
    padding: 20px 0;
    display: flex;
    .second-title {
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        color: #878787;
    }
    .line {
        width: 100%;
        margin-top: 10px;
        border-top: 1px dashed #cccccc;
    }
}

.second-label {
    text-align: right;
    padding-right: 20px;
    color: #595959;
    font-size: 14px;
}

.second-content {
    font-size: 12px;
    color: #a6a6a6;
    padding: 20px 0;
}

.progress_loading {
    padding: 20px 0;
    margin-top: 60px;
    margin-bottom: 30px;
}
.progress_img {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.loading_text {
    color: #3C7FFF;
    text-align: center;
    font-size: 14px;
}
.import_text {
    text-align: center;
    font-size: 14px;
    color: #333333;
    line-height: 62px;
}
</style>
